@mixin flex-center
  display: flex
  justify-content: center
  align-items: center

@mixin default-a
  color: unset
  text-decoration: none
  &:visited
    color: unset

@mixin title($bg-color: white, $main-color: #128ff2)
  color: $bg-color
  font-size: 1.7rem
  font-family: Roboto, sans-serif
  background-color: $main-color
  width: 100%
  height: 80px
  line-height: 80px
  box-shadow: 0 4px 0 rgb(red($main-color) green($main-color) #{blue($main-color)} / 30%)
  position: relative

@mixin title-button-left($main-color: #128ff2)
  top: 50%
  transform: translateY(-50%)
  left: 10px
  width: 25px
  height: 25px
  position: absolute
  display: inline-block
  vertical-align: middle
  padding: 10px
  transition: background-color ease-in-out .3s
  border-radius: 50%
  background-color: rgba(white,.2)
  line-height: 25px
  fill: white
  color: white
  cursor: pointer
  user-select: none
  &:hover, &:active
    background-color: white
    fill: $main-color
    color: $main-color

@mixin base-shadow
  box-shadow: 5px 5px 0 rgb(0 0 0 / 5%)
  transition: box-shadow .3s
  &:hover
    box-shadow: 7px 7px 0 rgb(0 0 0 / 9%)
