










































@import "src/assets/style/global"
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@700&display=swap')
$main-color: #128ff2
$bg-color: white
$vice-color: #59687f
$edge-spacing: 20px
$page-width: 80vw
.friend
  display: flex
  flex-direction: column
  min-height: 100vh
  .notice
    margin: $edge-spacing auto
    padding: $edge-spacing 0
    width: 100%
    max-width: $page-width
    border-bottom: 1px solid lighten($vice-color, 40%)
    line-height: 1.6rem
    a
      @include default-a
      color: #128ff2 !important
      display: inline-block
      margin: 0 5px
      &:hover
        text-decoration: underline
.title
  @include title
  .back svg
    @include title-button-left
.list-container
  flex-grow: 1
  .list
    width: 100%
    justify-content: center
    align-items: center
    text-align: left
    display: flex
    flex-wrap: wrap
    max-width: $page-width
    margin: 0 auto
  .card
    $border-color: #ebeff2
    $card-width: 350px
    background-color: $bg-color
    border-radius: 8px
    min-width: 300px
    word-break: break-all
    @media screen and (max-width: $card-width + 100px)
      min-width: auto
      width: 100%
    color: $vice-color
    @include base-shadow
    margin: 20px
    overflow: hidden
    .description
      padding: 10px
      text-align: center
      background-color: darken($bg-color, 3%)
    .name
      font-size: 1.2rem
      margin-bottom: 10px
      text-align: center
      padding: 15px 30px
      border-bottom: 1px solid $border-color
    .link
      font-size: 1rem
      margin: 10px
      border: 1px solid $border-color
      border-radius: 5px
      text-align: center
      .favicon
        width: 20px
        height: 20px
        vertical-align: middle
        margin-right: 5px
        background-color: $bg-color
      a
        padding: 15px 20px
        display: block
        @include default-a
        transition: color .3s
        &:hover
          color: $main-color !important
.footer
  font-size: 0.9rem
  height: 50px
  line-height: 50px
  background-color: #fff
  border-top: 1px solid #e2e2e2
  font-weight: bold
  font-family: 'Nunito Sans', sans-serif
  a
    @include default-a
